.mainCardContainer {
  display: flex;
  width: 90%;
  margin: 200px auto 0;
  align-items: center;
  padding: 40px;
  justify-content: space-around;
  /* background-color: antiquewhite; */
  border-radius: 16px;
  /* margin-top: -50px; */
}

.LeftImgContainer {
  width: 50%;
  margin-top: -180px;
}

.rightTextContainer {
  width: 40%;
  text-align: initial;
}

.CardTitles {
  font-size: 30px;
  font-weight: 600;
}

.cardpara {
  font-size: 25px;
  font-weight: 500;
}

.pointsListing {
  & li {
    font-size: 25px;
    font-weight: 400;
    position: relative;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  & li::before{
    content: "•";
    position: absolute;
    top: 0;
    left: -20px;
   
  }
}

.noteText {
  font-size: 25px;
  font-weight: 500;
}


@media only screen and (max-width: 767px) and (min-width: 320px) {
  .mainCardContainer {
    display: block;
    width: 90%;
    padding: 10px;
  }

  .LeftImgContainer{
    width: 100%;
    & img {
      width: 100%;
    }
  } 

  .rightTextContainer {
    width: 100%;
    
  }

  .CardTitles {
    font-size: 24px;
  }
  
  .cardpara {
    font-size: 20px;
    font-weight: 500;
  }
  
  .pointsListing {
    & li {
      font-size: 20px;
    }
  }

  .noteText {
    font-size: 20px;
  
  }
}