/* .mainContainer{
    width: 95%;
    margin: 0 auto;
} */

.mainContainer{
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    z-index: 50;
    padding-top: 80px;
}

.testContainer{
    width: 95%;
    margin: 0 auto;
}
.testDetails{
    display: flex;
    justify-content: space-between;
}
.noofPages{
    display: flex;
    justify-content: space-between;
    width: 60%;
    align-items:center ;
}
.noofPages > p {
    color: rgba(0, 0, 0, 0.25);
    font-size: 20px;
}
.noofPages > p > span{
    background-color: rgba(0, 0, 0, 0.25);
    color: #fff;
    padding: 5px 10px;
    margin-left: 20px;
    border-radius: 5px;
}
.submitBtn{
    color: #fff;
    background-color: #EB8413;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    width: 20%;
    padding: 5px 0;
    border-radius: 5px;
    cursor: pointer;
}
.totalTime{
    width: 25%;
    display: flex;
    gap: 20px;
    align-items: center;
}
.box{
    width: 10%;
    text-align: center;
}
.timer{
    color: #fff;
    background-color: #13868B;
    border-radius: 5px;
    padding: 10px 0;
}
.hr{
    font-size: 20px;
    color: rgba(0, 0, 0, 0.25);
}

.queContainer{
    display: flex;
    justify-content: space-between;
}
.queBox{
    width: 65%;
    border-right: 3px solid rgba(217, 217, 217, 0.5);
    padding: 40px;
    box-sizing: border-box;
}
.que{
    font-size: 25px;
}
.answer{
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 5px 10px ;
    margin-bottom: 30px;
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: initial;
    gap: 10px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 25px;
    color: #000;
    & div{
       padding: 10px;
      
      
    }
}
.navigation{
    display: flex;
    justify-content: space-between;
    margin-top: 150px;
}
.navBtn{
    color: #fff;
    background-color: #3F51B5;
    border-radius: 5px;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    width: 20%;
    cursor: pointer;
    padding: 5px 0;
}
.queOverview{
    width: 30%;

    & h3{
        font-size: 20px;
    }
}
.Numberbox{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    height: 300px;
    width: 300px;
    align-content: baseline;
    overflow: auto;
}
.totalQestionBox{
    width: 10%;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    height: fit-content;
}
.activequestion{
    background:linear-gradient(180deg, rgba(19,134,139,1) 40%, rgba(3,44,107,1) 100%) ;
    color: #fff;
}
.yourAnswer{
    border: 1px solid #000;
}

/* model css */

.resultModel > h3{
    font-size: 32px;
    font-weight: 500;
    color: #032C6B;
}
.resultModel > p {
    font-size: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
}
.closebtn{
    text-align: right;
}
.iconlist{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.textIcon{
    width: 50%;
    display: flex;
    gap: 20px;
    align-items: center;
    /* justify-content: space-between; */
    font-size: 24px;
    font-weight: 400;
    color: #000;
}
.imgcontainer{
    width: 10%;
    height: 25px;
}
.imgcontainer > img{
    width: 100%;
    height: 100%;
}
.timeTeken{
    width: 20%;
    font-size: 24px;
    font-weight: 500;
    color: #032C6B;
}
.btnContainer{
    display: flex;
    justify-content: space-around;
}
.cancelBtn, .viewBtn{
    width: 45%;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
    padding: 5px;
    border-radius: 5px;
}
.cancelBtn{
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
}
.viewBtn{
    color: #fff;
    background: linear-gradient(90deg, rgba(19,134,139,1) 30%, rgba(3,44,107,1) 100%);
    border: none;
}

.rulesContainer{
    width: 80%;
    margin: 0 auto 50px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
    padding: 40px;
    box-sizing: border-box;
    border-radius: 10px;
}
.rulesHeading{
    font-size: 28px;
    font-weight: 700;
    color: #032C6B;
}
.rulesImagesText{
    display: flex;
    justify-content: space-around;
    background-color: #13868B;
    color: #fff;
    border-radius: 10px;
    padding:10px 20px;
}
.rImgText{
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px 0;
}

.rTextContainer{
    text-align: center;
    & h4{
        font-size: 24px;
        font-weight: 700;
    }

    & p{
        font-size: 24px;
        font-weight: 400;
    }
    
}

.rulesH{
    font-size: 24px;
    font-weight: 500;
    color: #032C6B;
}
.noOfRules{
    margin: 20px 0;
    line-height: 1.4;
    font-size: 18px;
    font-weight: 400;
}
.quesMarks{
    text-align: right;
    
    /* width: fit-content; */
}
.quesMarks > p{
    width: 80px;
    text-align: center;
    margin: 10px auto;
    margin-right: 0 !important;
    padding: 8px 20px;
    border-radius: 5px;
    white-space: nowrap;
    color: #13868B;
    font-size: 20px;
    font-weight: 400;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10);
}
.gotoText{
    background-color: transparent;
    border: 1px solid #032C6B;
    color: #032C6B;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 400;
    padding: 5px 20px;
}
.onlyMobileShow{
    display: none;
}

.ArrowBacktext{
    font-size: 20px;
    font-weight: 600;
    color: #032C6B;
    margin-left: 10px;
}
.boxtoinfo{
    display: flex;
    gap: 20px;
    align-items: center;

    & p{
        font-size: 20px;
    }
}
/* .boxtoinfo:first-child > .inforefBox{
    background-color: #fff;
} */
.inforefBox{
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background: linear-gradient(180deg, rgba(19,134,139,1) 30%, rgba(3,44,107,1) 100%);
}
@media only screen and (max-width: 992px) and (min-width: 320px) {
    .testDetails{
        display: none;
    }
    .queBox{
        width: 100%;
        border: none;
        padding: 0;
    }

    .queOverview{
        display: none;

    }

    .onlyMobileShow{
        display: block;

        .totalTime{
            width: 100%;
        }
        .submitBtn{
            
        }
        
    }

    .Numberboxmobile{
        display: flex;
        overflow: auto;
        margin: 20px 0;
        gap: 10px;
    }
    .totalQestionBox{
        width: 50px;
        margin: 3px;
    }
    .submitBtn{
        width: 45%;
        font-size: 16px;
        height: fit-content;
    }
    .navBtn{
        width: 45%;
        font-size: 16px;
        margin-bottom: 20px;
    }
    
    .rulesContainer{
        width: 90%;
        padding: 20px;
    }
    .rulesImagesText{
        display: flex;
        flex-direction: column;
    }
    .rImgText{
        justify-content: space-around;
    }
    .mobileS{
        width: 250px !important;
        padding: 20px !important;
    }
    .resultModel{
        
        & h3{
            font-size: 18px;
        }

        & p{
            font-size: 16px;
        }

    }

    .textIcon{
        width: 60%;
    }
    .imgcontainer{
        width: 30px;
    }
    .cancelBtn, .viewBtn{
        font-size: 18px;
    }
    
}