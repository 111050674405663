.HomeBanner {
  position: relative;
}
.backgroundBanner {
  width: 100%;
  /* height: 100vh; */
  /* background-color: brown; */
  /* background: linear-gradient(
    90deg,
    rgba(17, 138, 138, 1) 0%,
    rgba(17, 138, 138, 0.8) 50%
  ); */

  & img {
    width: 100%;
  }
}

.bannerTexts {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 750px;
}
.bannertagLine {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.4;
}

.bannerpara {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.4;

  & span {
    font-weight: 500;
    color: #032c6b;
  }
}

.Explorebtnsection {
  padding: 50px 0;
  position: relative;
}
.ExploreBackground {
  background-color: #82a0d8;
  width: 100%;
  height: 250px;
  & img {
    width: 100%;
  }
}

.exploreimgTextbtn {
  display: flex;
  position: absolute;
  width: 60%;
  top: 35%;
  left: 20%;
  justify-content: space-between;
  align-items: center;

  & h3 {
    font-size: 34px;
    font-weight: 600;
    color: #ffffff;
  }

  & .explorbtn {
    background-color: #11898a;
    color: #ffffff;
    box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25);
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
  }
}
.exploremobile {
  display: none;
}

.LJOS {
  background-color: #d8fffd;
  padding: 20px 40px 60px;
  text-align: center;
  position: relative;
}
.riconone {
  top: -5%;
}
.ricontwo {
  bottom: 20%;
  left: 8%;
}
.riconthree {
  bottom: -15%;
  right: 0;
}
.imagestoshow {
  display: flex;
  width: 70%;
  margin: 0 auto;
  justify-content: space-between;
  & .LJOSimgContainer {
    width: 45%;

    & img {
      width: 100%;
      border-radius: 25px;
    }
  }
}
.LJOSimgContainer {
  width: 40%;
  margin: 0 auto;

  & img {
    width: 100%;
    border-radius: 25px;
  }
}

.hisName {
  font-size: 30px;
  color: #032C6B;
}

.iitslogoandname {
  display: flex;
  justify-content: center;
  gap: 30px;
  /* color: #032C6B; */
}

.iitsName {
  font-size: 25px;
  color: #032C6B;
}
.hisQuote {
  font-size: 22px;
  color: #032C6B;
}
.textContainer {
  & .sectionHeading {
    font-size: 40px;
    font-weight: 600;
    color: #032c6b;
  }

  & p {
    font-size: 18px;
    width: 80%;
    font-weight: 400;
    color: rgba(3, 44, 107, 0.8);
    margin: 40px auto;
  }
}
.LJOSbtnContainer {
  margin-top: 50px;
}
.btnGradient {
  background: linear-gradient(
    90deg,
    rgba(17, 137, 138, 1) 0%,
    rgba(44, 91, 150, 1) 100%
  );
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 700;
}

.howitsWork {
  /* padding: 100px 100px 2180px; */
  padding: 100px 100px;
  position: relative;
  text-align: center;
}

.howitspara {
  font-size: 25px;
  font-weight: 500;
  color: #032c6b;
  width: 80%;
  margin: 10px auto;
  line-height: 1.4;
}
.howIcon1 {
  top: 17%;
  left: 5%;
}

.howIcon2 {
  top: 38%;
  left: 15%;
}
.howIcon3 {
  right: 5%;
  top: 65%;
}
.howIcon4 {
  bottom: 24%;
  left: 40%;
}

.howIcon5 {
  bottom: 10%;
  right: 5%;
}

.howIcon6 {
  bottom: 5%;
  left: 0;
}
.sectionHeading {
  font-size: 40px;
  font-weight: 600;
  color: #032c6b;
}

.howitsWork_Backgoundline {
  position: absolute;
  top: 20%;
  left: 30%;
}
.hdiw_maincontainer {
  /* position: absolute; */
  top: 10%;
  margin: 0 auto;
  left: 20%;
  & li {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    /* width: 80%; */
    align-items: center;
  }

  & li:nth-child(odd) {
    flex-direction: row;
  }
}
.hdiw_imgContainer {
  width: 45%;

  & img {
    width: 100%;
  }
}

.InfoBox {
  width: 45%;
  padding: 10px 30px;
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 6px 10px 41px 0 rgba(20, 130, 139, 0.1);
  text-align: left;

  & h5 {
    font-size: 20px;
    font-weight: 600;
    color: #032c6b;
  }

  & p {
    font-size: 18px;
    font-weight: 400;
    color: #032c6b;
  }
}

.servicesSection {
  /* padding-top: 100px; */
  background-color: #f3f1f5;
  padding: 40px;
  text-align: center;
}

.servicessContainer {
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin: 50px auto;
}

.serviceInfopara {
  font-size: 18px;
  font-weight: 400;
  color: rgba(3, 44, 107, 0.8);
}

.gallerySection {
  background-color: #f7ecff;
  text-align: center;
  /* margin: 70px 0; */
  padding: 30px 30px 90px;
  position: relative;
}
.patnerSchoolSection {
  padding: 70px 0;
  position: relative;
}
.maxWidth {
  max-width: 1330px !important;
  margin: 0 auto;
  padding: 0 10px;
}

.textCenter {
  text-align: center !important;
}

.bluiesh {
  color: #2f327d;
}
.featuresdiv {
  margin-top: 0;
  font-weight: 600;
  font-size: 32px;
}
.patnerSchoolSection {
  background-color: #f7ecde;
}

.contactformSection {
  text-align: center;
  /* padding: 50px 0; */
  padding: 50px;
  position: relative;
}
.innercontactContainer{
  display: flex;
  justify-content: space-around;
}

.contactformImgContainer{
  width: 35%;
  background: radial-gradient(circle, rgba(37,103,147,0.21052170868347342) 0%, rgba(255,255,255,1) 70%);
  overflow: visible;

  & img{
    width: 100%;
  }
}
.borderContainer {
  /* margin: 0 auto; */
  width: 35%;
  padding: 4px;
  background: rgb(20, 135, 139);
  background: linear-gradient(
    120deg,
    rgba(20, 135, 139, 1) 0%,
    rgba(170, 212, 212, 1) 50%,
    rgba(20, 135, 139, 1) 100%
  );
  box-sizing: border-box;
}
.fromContainer {
  background-color: #fff;
  padding: 40px;
  & .sectionHeading {
    margin: 0;
  }
}
.bgacolor {
  background-color: rgba(217, 217, 217, 0.15);
}
.textfieldContainer {
  padding: 2px 40px;

  & .fullwidth {
    margin: 10px 0;
    width: 100%;
  }
}

.gradeContainer {
  display: flex;
  flex-wrap: wrap;
  /* row-gap: 20px; */
  column-gap: 25px;
  width: 60%;
  margin: 0 auto;
}

.minBox {
  border: 1px solid #2b5f96;
  border-radius: 5px;
  width: 50px;
  box-sizing: border-box;
  padding: 8px 0;
  cursor: pointer;
}

.textbtn {
  border: 1px solid #2b5f96;
  width: 70%;
  margin: 20px auto;
  padding: 10px 0;
  border-radius: 5px;
}
.fromsubmitbtn {
  background-color: #11898a !important;
  color: #ffffff !important;
  box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25) !important;
  padding: 10px 30px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-top: 40px !important;
  width: 100% !important;
}
.showmobile {
  display: none;
}

.active {
  background-color: #12888b;
  color: #fff;
  border: none;
}
.postions {
  position: absolute;
}

.iconboxone {
  top: 45%;
  right: 12%;
}
.iconboxtwo {
  left: 0;
  top: 60%;
}

.cfone {
  top: 10%;
  left: 3%;
}
.cftwo {
  bottom: 10%;
  left: 4%;
}
.cfthree {
  bottom: 0;
  right: 15%;
}

.galleryIcon1 {
  top: 10px;
  right: 0;
}
.galleryIcon2 {
  left: 0;
  top: 25px;
}

.psicons1 {
  bottom: 20%;
}
.psicons2 {
  bottom: 0;
  right: 0;
}

.formsubmited {
  font-size: 80px;
  background-color: #5cb85c;
  border-radius: 100px;
  color: #fff;
  padding: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.mainpointers {
  & li {
    display: block;
    list-style: disc;
    position: relative;
  }

  & li::before {
    content: "•";
    position: absolute;
    width: 10px;
    height: 10px;
    left: -20px;
    top: -3px;
    font-size: 25px;
  }
}

.mainpointerstext {
  font-size: 20px;
  line-height: 1.4;
}

/* association section */
.accociationSection {
  background-color: #82a0d8;
  text-align: center;
  position: relative;
  padding: 10px 40px 80px;
}

.maininnerlogoText {
  display: flex;
  justify-content: center;
  gap: 300px;
}

.logotext {
  display: flex;
  gap: 30px;
  align-items: center;
}

.innerlogocontainer {
  width: 150px;

  & img {
    width: 100%;
  }
}

.widthforlogo {
  width: 100px !important;
  & img {
    width: 100%;
  }
}

.onlytext {
  font-size: 34px;
  font-weight: 600;
  color: #fff;
}

.mainforflex {
  display: flex;
  align-items: center;
}
.iitslogoConatiner {
  width: 100px;

  & img {
    width: 100%;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .bluiesh {
    color: #2f327d;
    font-weight: 600;
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  .mobile_container {
    margin: auto 9%;
    margin-bottom: 65px;
  }
  .featuresdiv {
    /* font-size: 40px;
        margin-bottom: 0; */
  }
  .featuresdiv {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 28px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .bannerTexts {
    /* position: static; */
    width: 100%;
    transform: none;
    top: 5%;
    left: 5%;
  }

  .bannertagLine {
    font-size: 24px;
    font-weight: 700;
  }
  .bannerpara {
    font-size: 16px;
    font-weight: 400;
  }

  .exploreimgTextbtn {
    width: 98%;
    top: 25%;
    left: 5px;
    justify-content: space-evenly;

    & h3 {
      font-size: 16px;
      font-weight: 600;
    }
    & div {
      width: 40px;

      & img {
        width: 100%;
      }
    }

    & .explorepc {
      display: none;
    }
  }
  .exploremobile {
    display: block;
    text-align: center;
    position: absolute;
    bottom: 40%;
    left: 20%;

    & .explorbtn {
      background-color: #11898a;
      color: #ffffff;
      box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25);
      padding: 10px 30px;
      font-size: 16px;
      font-weight: 700;
    }
  }
  /* .iconboxone {
    right: 15%;
    width: 45px;
  } */
  /* .iconboxtwo {
    left: -115%;
    top: 32%;
  } */

  .iconboxone {
    right: 25%;
    width: 45px;
    bottom: 0;
    top: auto;
  }

  .iconboxtwo {
    left: -115%;
    top: auto;
    bottom: 10%;
  }

  .LJOS {
    padding: 20px;
  }

  .riconone {
    top: -10%;
  }
  .ricontwo {
    width: 70px;
    bottom: 1%;
    left: 2%;

    & img {
      width: 100%;
    }
  }
  .riconthree {
    width: 80px;
    bottom: -12%;
    right: 0;

    & img {
      width: 100%;
    }
  }
  .textContainer {
    & p {
      font-size: 14px;
      font-weight: 400;
      width: 100%;
    }
  }
  .sectionHeading {
    font-size: 24px !important;
    font-weight: 600;
  }
  .imagestoshow {
    display: block;
    width: 100%;

    & .LJOSimgContainer {
      width: 100%;
      margin-top: 20px;

      & img {
        width: 100%;
      }
    }
  }

  .hidemobile {
    display: none;
  }
  .showmobile {
    display: block;
  }
  .howitsWork {
    padding: 50px 5px;
    box-sizing: border-box;
  }

  .howIcon1 {
    display: none;
    width: 50px;
    top: -2%;
    left: 0;
  }

  .howIcon2 {
    width: 80px;
    top: 28%;
    left: 5%;

    & img {
      width: 100%;
    }
  }
  .howIcon3 {
    width: 70px;
    right: 5%;
    top: 48%;
    & img {
      width: 100%;
    }
  }
  .howIcon4 {
    width: 50px;
    bottom: 22%;
    left: auto;
    right: 3px;
    & img {
      width: 100%;
    }
  }

  .howIcon5 {
    width: 129px;
    bottom: -20%;
    right: 0;
    /* display: none; */
    & img {
      width: 100%;
    }
  }

  .howIcon6 {
    width: 199px;
    bottom: -2%;
    left: 0;
    & img {
      width: 100%;
    }
  }
  .howitsWork_Backgoundline {
    left: 10%;
    top: 21%;
  }

  .hdiw_maincontainer {
    left: 0;
    top: 5%;

    /* padding: 20px; */
    & li {
      display: block;
      width: 90%;
    }
  }
  .hdiw_imgContainer {
    width: 100%;
  }
  .InfoBox {
    width: 100%;
    background-color: #ffffff;
    margin: 35px 0;
    padding: 10px 15px;
  }

  .serviceInfopara {
    font-size: 16px;
    font-weight: 400;
  }

  .servicessContainer {
    flex-direction: column;
    gap: 40px;
  }

  .borderContainer {
    width: 100%;
  }
  .gradeContainer {
    width: 100%;
    column-gap: 25px;
  }
  .fromContainer {
    padding: 20px;
  }
  .textfieldContainer {
    padding: 10px;
  }

  .galleryIcon1 {
    width: 266px;
    top: auto;
    bottom: -25%;
    right: 0;
    & img {
      width: 100%;
    }
  }
  .galleryIcon2 {
    width: 71px;
    left: 0;
    top: 25px;
    & img {
      width: 100%;
    }
  }

  .cfone,
  .cftwo,
  .cfthree {
    display: none;
  }

  .psicons1 {
    bottom: auto;
    top: 0;
  }
  .psicons2 {
    bottom: 52px;
    right: 0;
  }

  /* association section */
  .accociationSection {
    background-color: #82a0d8;
    text-align: center;
    position: relative;
    padding: 10px 40px 80px;
  }

  .maininnerlogoText {
    gap: 20px;
  }

  .logotext {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .innerlogocontainer {
    width: 50px;

    & img {
      width: 100%;
    }
  }

  .widthforlogo {
    width: 50px !important;
    & img {
      width: 100%;
    }
  }

  .onlytext {
    font-size: 20px;
    white-space: nowrap;
    font-weight: 600;
    color: #fff;
  }

  .mainforflex {
    flex-direction: column;
  }
  .LJOSimgContainer {
    width: 100%;
  }

  .iitslogoConatiner {
    width: 80px;

    & img {
      width: 100%;
    }
  }

  .hisName {
    font-size: 20px;
  }

  .iitslogoandname {
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  .iitsName {
    font-size: 25px;
  }
  .hisQuote {
    font-size: 22px;
  }

  .howitspara {
    font-size: 16px;

    width: 100%;
  }


  .contactformSection{
    padding: 20px;
  }
  .innercontactContainer{
    /* display: flex; */
    justify-content: space-around;
  }

  .contactformImgContainer{
   display: none;
  
    & img{
      width: 100%;
    }
  }
}
